import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TAG_AVATAR } from './models/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router
  ) {}

  title = 'grainsmart-web-collab';
  img = null

  ngOnInit(): void {
    
  }
 
  getAvatar() {
    let avatar = localStorage.getItem(TAG_AVATAR)
    
    return avatar == null || avatar == 'null' ? null : avatar;
  }

  onLogout() {
    localStorage.clear();
    this.router.navigate([''])
  }
}
