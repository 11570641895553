// base url
// export const BASE_URL: string = 'http://localhost:3300'; //dev
export const BASE_URL: string = 'https://collab.pay-retail.com'; //uat

// export const LOGIN_BASE_URL: string = 'http://localhost:3000'; //dev
export const LOGIN_BASE_URL: string = 'https://pay-retail.com';

// local db tag
export const TAG_TOKEN: string = 'token';
export const TAG_USERNAME: string = 'username';
export const TAG_ROLE: string = 'role';
export const TAG_AVATAR: string = 'avatar'

// header key for token
export const HEADER_TOKEN: string = 'auth-token';

export const Source: any = {
    WEB: 'WEB',
    DEV: 'DEV',
  };