<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="">Collaboration</a>
    <div class="ml-auto" style="margin-right: 2%;">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-4" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar-list-4">
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img *ngIf="!getAvatar()" src="assets/profile_icon.png" width="40" height="40" class="rounded-circle">
                    <img *ngIf="getAvatar()" [src]="getAvatar()" width="40" height="40" class="rounded-circle">
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <!-- <a class="dropdown-item">Dashboard</a> -->
                    <a class="dropdown-item" routerLink="category">Categories</a>
                    <a class="dropdown-item" (click)="onLogout()">Log Out</a>
                </div>
                </li>   
            </ul>
        </div>
    </div>
    
  </nav>

<div style="margin-top: 2%;">
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
    <router-outlet></router-outlet>
</div>
